// src/Routes.tsx
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import QuemSomosPage from "./Pages/QuemSomosPage";
import HomePage from "./Pages/HomePage";

const RoutesComponent: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/quem-somos" element={<QuemSomosPage />} />
      </Routes>
    </Router>
  );
};

export default RoutesComponent;
