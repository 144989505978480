import React from "react";
import { Button, Title, Subtitle } from "./TitleButtonComponentStyles";

const TitleButton: React.FC = () => {
  const phoneNumber = "+5581996188006";
  const message = encodeURIComponent(
    "Olá, eu vim pelo site! Gostaria de tirar algumas dúvidas..."
  );
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${message}`;

  return (
    <>
      <Title>Todos os seguros em um só lugar!</Title>
      <Button href={whatsappLink} target="_blank" rel="noopener noreferrer">
        Clique aqui !
      </Button>
      <Subtitle>e receba nosso orçamento.</Subtitle>
    </>
  );
};

export default TitleButton;
