import React from "react";
import { GlobalStyles } from "../GlobalStyle";
import Header from "../components/Header";
import BannerComponent from "../components/BannerComponent";
import TitleButton from "../components/TitleButton";
import Cards from "../components/Cards";
import ContactBar from "../components/ContactBar/ContactBar";
import Footer from "../components/Footer";

const images = [
  "/assets/32-Seguro-Auto.jpg",
  "/assets/48-Seguro-Residencia.jpg",
  "/assets/62-Seguros-Empresariais.jpg"
];

function HomePage() {
  return (
      <>
        <GlobalStyles />
        <Header />
        <BannerComponent images={images} />
        <TitleButton/>
        <Cards />
        <ContactBar/>
        <Footer/>
      </>
  );
}

export default HomePage;

