// Card.tsx
import React from "react";
import { CardContainer, CircleNumber } from "./CardStyles";

interface CardProps {
  number: number;
  title: string;
  description: string;
  backgroundColor?: string;
}

const Card: React.FC<CardProps> = ({
  number,
  title,
  description,
  backgroundColor
}) => {
  return (
    <CardContainer>
      <CircleNumber backgroundColor={backgroundColor || ""}>
        {number}
      </CircleNumber>
      <h2>
        {title}
      </h2>
      <p>
        {description}
      </p>
    </CardContainer>
  );
};

export default Card;
