import React from "react";
import styled from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ContactBar from "../components/ContactBar/ContactBar";


const QuemSomosContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  text-align: justify;
  font-family: 'Mukta', sans-serif;   


  h2 {
    font-size: 2.5em;
    color: #002960;
    padding-top: 30px;
  }

  p {
    font-size: 1.2em;
    line-height: 1.6;
    color: #002960;
  }
`;

const FullWidthHeader = styled(Header)`
  font-family: 'Mukta', sans-serif;   
    
`;

const QuemSomosPage: React.FC = () => {
  return (
    <>
      <FullWidthHeader />
      <hr style={{ color: "#002960" }} />
      <QuemSomosContainer>
        <h2>Quem Somos</h2>
        <p>
          Há mais de 10 anos no mercado, a BRDN SEGUROS especializou-se em
          oferecer soluções em todos os ramos de seguros para empresas, pessoas,
          órgãos públicos e demais segmentos da sociedade. Além dos seguros
          tradicionais, operamos também com consórcios, previdência privada,
          seguros saúde e odonto, garantia judiciária, fiança locatícia, entre
          outros.
          <br />
          <br />
          <strong>Visão:</strong> Ser reconhecida como provedora total de
          soluções de seguridade em cada segmento e perfil de cliente.
          <br />
          <br />
          <strong>Missão:</strong> Oferecer ao mercado soluções de seguridade
          que atendam as necessidades de cada segmento e perfil de cliente,
          garantindo assistência diferenciada e satisfação plena para clientes e
          fornecedores.
          <br />
          <br />
          <strong>Valores:</strong> Ética na relação com todos os interessados.
          Rapidez entregando dentro do prazo e expectativa dos interessados.
          Transparência em todas as etapas de relacionamento e negócios.
          Organização como base de uma gestão diferenciada. Credibilidade como
          reconhecimento do mercado. Segurança como produto final da relação
          BRDN - Seguradoras - Clientes.
        </p>
          <br />
          <br />
      </QuemSomosContainer>
      <ContactBar/>
      <Footer/>

    </>
  );
};

export default QuemSomosPage;
