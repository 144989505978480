import React, { useState, useEffect } from "react";
import {
  BannerWrapper,
  SlideWrapper,
  Slide,
  CarouselPrevButton,
  CarouselNextButton
} from "./BannerComponentStyles";

interface BannerComponentProps {
  images: string[];
}

const BannerComponent: React.FC<BannerComponentProps> = ({ images }) => {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(
    () => {
      const interval = setInterval(() => {
        setCurrentImage(prev => (prev + 1) % images.length);
      }, 5000); // Change image every 5 seconds (adjust as needed)

      return () => clearInterval(interval);
    },
    [images.length]
  );

  const prevImage = () => {
    setCurrentImage(prev => (prev - 1 + images.length) % images.length);
  };

  const nextImage = () => {
    setCurrentImage(prev => (prev + 1) % images.length);
  };

  return (
    <BannerWrapper>
      <SlideWrapper
        style={{ transform: `translateX(-${currentImage * 100}%)` }}
      >
        {images.map((url, index) =>
          <Slide key={index}>
            <img src={url} alt={`Slide ${index + 1}`} />
          </Slide>
        )}
      </SlideWrapper>
      <CarouselPrevButton onClick={prevImage}>
        {"<"}
      </CarouselPrevButton>
      <CarouselNextButton onClick={nextImage}>
        {">"}
      </CarouselNextButton>
    </BannerWrapper>
  );
};

export default BannerComponent;
