// Footer.tsx
import React from "react";
import { FooterWrapper } from "./FooterStyles";

const Footer: React.FC = () => {
  const openWhatsApp = () => {
    const message = encodeURIComponent(
      "Olá, tudo bem? Eu visitei o site e gostaria de saber mais informações."
    );
    const phoneNumber = "+5581996188006";
    window.open(`https://wa.me/${phoneNumber}?text=${message}`, "_blank");
  };

  return (
    <FooterWrapper>
      <p>
        &copy; 2023 BRDN Corretora de Seguros.{" "}
        <span
          onClick={openWhatsApp}
          style={{ cursor: "pointer", textDecoration: "underline" }}
        >
          Desenvolvido por Lucas Almeida
        </span>
      </p>
    </FooterWrapper>
  );
};

export default Footer;
