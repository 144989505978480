// HeaderStyles.ts
import styled from "styled-components";

export const HeaderWrapper = styled.header`
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
  font-family: 'Mukta', sans-serif;

  .logo {
    img {
      max-width: 200px;
      margin-top: 10px;
    }
  }

  nav {
    ul {
      list-style: none;
      display: flex;
      margin: 0;
      padding: 0;

      li {
        margin-right: 20px;

        a {
          text-decoration: none;
          text-transform: uppercase;
          color: #002960;

          &:hover {
            text-decoration: underline;
          }
        }

        .submenu {
          display: none;
          background-color: #002960;
          padding: 10px;
          z-index: 4; /* Um valor maior do que o z-index do banner */
          position: absolute; /* Mantenha a posição absoluta para o submenu */

          li {
            margin: 0;
            a {
              color: #fff;
            }
          }
        }
      }

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;

        li {
          margin: 10px 0;
        }

        .submenu {
          position: relative;

          li {
            margin-top: 20px;
          }
        }
      }

      li:hover .submenu {
        display: block;
      }
    }
  }
`;
