// BannerStyles.ts
import styled from "styled-components";

export const BannerWrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1; /* Certifique-se de ajustar conforme necessário para garantir que seja maior */
`;

export const SlideWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
`;

export const Slide = styled.div`
  min-width: 100%;
  box-sizing: border-box;

  img {
    width: 100%;
    height: auto;
  }
`;

export const CarouselPrevButton = styled.button`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  outline: none;
`;

export const CarouselNextButton = styled.button`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  outline: none;
`;
