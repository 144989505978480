// Header.tsx
import React from "react";
import { HeaderWrapper } from "./HeaderStyles";
import { Link } from "react-router-dom";

const Header: React.FC = () => {
  const formularioLink = "";
  const formularioLinkVida = "https://forms.gle/7WY4D7f7LL7ZRRfdA";
  const formularioLinkAutoMoto = "https://forms.gle/mW1jHzWfGMQYkGeZ7";
  const formularioLinkOdonto = "https://forms.gle/4Xg9bmi4C1TKTssz7";
  const formularioLinkConsorcio = "https://forms.gle/nxf7JPcV59HeT9i68";
  const formularioLinkPrevidencia = "https://forms.gle/TirceVepqzuWbVxT7";

  const formularioLinkEmpresarialAutoMoto =
    "https://forms.gle/jJwXnfNGArUvQmtr8";
  const formularioLinkEmpresarialVida = "https://forms.gle/yuJp2yn5imPKmouR8";
  const formularioLinkEmpresarialOdonto = "https://forms.gle/2xa6dzzTSyQd7HpD9";
  const formularioLinkEmpresarialConsorcio =
    "https://forms.gle/Rf34VCTCLEA9t6jG6";
  const formularioLinkEmpresarial = "https://forms.gle/vqmjfdRrqHYHWMsL8";
  const formularioLinkEmpresarialCondominio =
    "https://forms.gle/TUpcMnu6sR1gZnCs7";
  const formularioLinkEmpresarialGarantiaImobiliaria =
    "https://forms.gle/MPtUQpq57ufce5mu7";
  const formularioLinkEmpresarialGarantiaJudicial =
    "https://forms.gle/g29Yfwontebzka7h7";

  const whatsappNumber = "+5581996188006";

  return (
    <HeaderWrapper>
      <div className="logo">
        <img src={require("./logo-azul.png")} alt="Logo" />
      </div>
      <nav>
        <ul>
          <li>
            <Link to="/">Início</Link>
          </li>
          <li>
            <Link to={"/quem-somos"}>Quem Somos</Link>
          </li>
          <li>
            <a>Para Você</a>
            <ul className="submenu">
              <li>
                <a href={formularioLinkAutoMoto}>Auto e Moto</a>
              </li>
              <li>
                <a href={formularioLinkVida}>Vida</a>
              </li>
              <li>
                <a href={formularioLinkOdonto}>Odontológico</a>
              </li>
              <li>
                <a href={formularioLinkConsorcio}>Consórcio</a>
              </li>
              <li>
                <a href={formularioLinkPrevidencia}>Previdência Privada</a>
              </li>
            </ul>
          </li>
          <li>
            <a>Para Sua Empresa</a>
            <ul className="submenu">
              <li>
                <a href={formularioLinkEmpresarialAutoMoto}>Auto e Moto</a>
              </li>
              <li>
                <a href={formularioLinkEmpresarialVida}>Vida</a>
              </li>
              <li>
                <a href={formularioLinkEmpresarialOdonto}>Odontológico</a>
              </li>
              <li>
                <a href={formularioLinkEmpresarialConsorcio}>Consórcio</a>
              </li>
              <li>
                <a href={formularioLinkEmpresarial}>Empresarial</a>
              </li>
              <li>
                <a href={formularioLinkEmpresarialCondominio}>Condomínio</a>
              </li>
              <li>
                <a href={formularioLinkEmpresarialGarantiaImobiliaria}>
                  Garantia Imobiliária
                </a>
              </li>
              <li>
                <a href={formularioLinkEmpresarialGarantiaJudicial}>
                  Garantia Judicial
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href={formularioLink} target="_blank" rel="noopener noreferrer">
              Solicite uma Cotação
            </a>
          </li>
          <li>
            <a href={`tel:${whatsappNumber}`}>Contato</a>
          </li>
        </ul>
      </nav>
    </HeaderWrapper>
  );
};

export default Header;
