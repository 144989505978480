// GlobalStyles.ts
import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    /* background: url('./assets/1.png') no-repeat center center fixed;
    background-size: cover;  */

    font-family: 'Mukta', sans-serif;   
    
    @media (max-width: 768px) {
      background-attachment: scroll; /* Muda para scroll quando a largura da tela for menor que 768 pixels */
    }

  }
`;
