// ContactBar.tsx
import React from "react";
import { BarraMulti, BarraMultiLi } from "./ContactBarStyles";

const ContactBar: React.FC = () => {
  const openWhatsApp = () => {
    window.location.href = "https://api.whatsapp.com/send?phone=5581996188006";
  };

  const openEmail = () => {
    window.location.href = "mailto:almeidalcs.lucas@gmail.com?Subject=Contato";
  };

  const openCotacao = () => {
    window.location.href = "/propostaOnline.html";
  };

  return (
    <BarraMulti>
      <BarraMultiLi onClick={openWhatsApp}>
        <img src={require("./icon-zapzap.png")} alt="WhatsApp" />
        <span>(81) 99618-8006</span>
      </BarraMultiLi>

      <BarraMultiLi onClick={openEmail}>
        <img src={require("./email_14410.png")} alt="Email" />
        <span>Fale conosco</span>
      </BarraMultiLi>

      <BarraMultiLi onClick={openWhatsApp}>
        <img src={require("./forms_form_list_21786.png")} alt="Calculator" />
        <span>Solicite uma cotação</span>
      </BarraMultiLi>
    </BarraMulti>
  );
};

export default ContactBar;
