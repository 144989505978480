import styled, { keyframes } from "styled-components";

export const CardContainer = styled.div`
  border: 1px solid #ddd;
  color: #002960;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  width: 200px;
  margin-right: 20px;
  margin-bottom: 60px;
  background-color: #b4c7e1;

  @media (max-width: 768px) {
    /* Ajusta o tamanho do card para telas menores */
    width: 100%;
    max-width: 150px;
  }
`;

export const CircleNumber =
  styled.div <
  { backgroundColor: string } >
  `
  background-color: ${props => props.backgroundColor || "#002960"};
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 20px;
  line-height: 40px;
  margin-bottom: 10px;

`;

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap; /* Permite que os cards se movam para a linha seguinte em telas menores */
  justify-content: center;
  margin: 60px;

  @media (max-width: 768px) {
    /* Ajusta a margem para telas menores */
    margin: 20px;
  }
`;
