import styled, { keyframes } from "styled-components";

const pulseAnimation = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
`;

export const Title = styled.h1`
  text-align: center;
  font-weight: 900;
  font-size: 34px;
  margin-top: 4px;
  color: #002960;
`;

export const Subtitle = styled.h3`
  text-align: center;
  font-size: 18px;
  margin-top: 8px;
  margin-bottom: 40px;
  color: #002960;
`;

export const Button = styled.a`
  display: flex;
  justify-content: center;
  background-color: #feff00;
  color: #002960;
  padding: 10px 20px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 8px;
  text-decoration: none;
  margin: 0 auto; // Centraliza horizontalmente

  max-width: 200px; // Defina a largura máxima desejada

  animation: ${pulseAnimation} 1s infinite;

  &:hover {
    background-color: #52df35;
  }

  @media (min-width: 768px) {
    margin-top: 0;
  }
`;
