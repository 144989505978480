// index.tsx (dentro da pasta Cards)
import React from "react";
import Card from "./Card";
import { CardsContainer } from "./CardStyles";

const Cards: React.FC = () => {
  const cardsData = [
    {
      number: 1,
      title: "Seguro Auto",
      description:
        "Seguro auto online: Cote c/ até 12 seguradoras e receba a melhor proposta"
    },
    {
      number: 2,
      title: "Planos de Saúde",
      description:
        "Simule seu plano online com as 5 melhores empresas de plano de saúde"
    },
    {
      number: 3,
      title: "Plano Odontológico",
      description:
        "Os melhores planos para os melhores clientes, cote o melhor plano para você e sua família"
    },
    {
      number: 4,
      title: "Seguro de Vida",
      description:
        "Com os nossos seguros de vida garantimos muito mais segurança e tranquilidade"
    }
  ];

  return (
    <CardsContainer>
      {cardsData.map((card, index) => <Card key={index} {...card} />)}
    </CardsContainer>
  );
};

export default Cards;
