// ContactBarStyles.tsx
import styled from "styled-components";

export const BarraMulti = styled.div`
  width: 100%;
  height: 50px;
  position: fixed;
  bottom: 0;
  font-family: 'Source Sans Pro', sans-serif;
  z-index: 501;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px -5px 15px rgba(0, 0, 0, 0.1);
`;

export const BarraMultiLi = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease;
  margin-left: 60px;

  &:hover {
    transform: translateY(-5px);
  }

  img {
    width: 24px;
    height: 24px;
    margin-bottom: 8px;
  }

  span {
    font-size: 12px;
    text-align: center;
  }
`;
