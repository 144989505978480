// FooterStyles.ts
import styled from "styled-components";

export const FooterWrapper = styled.footer`
  height: 100px;
  padding-top: 20px;
  background-color: #d9d9d9;
  color: #002960;
  text-align: center;

  @media (max-width: 768px) {
    height: 130px;
    padding-top: 10px;
  }
`;
